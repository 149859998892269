'use client';

import { ReactNode } from 'react';
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { CHAIN_ID_TO_CHAIN } from '@/chains/chains';

const config = getDefaultConfig({
  appName: 'Redstone DAC Dashboard',
  projectId: 'fdc0458137210b399488c8eb335d0180',
  chains: [
    CHAIN_ID_TO_CHAIN[Number(process.env.NEXT_PUBLIC_L1_CHAIN_ID)],
    CHAIN_ID_TO_CHAIN[Number(process.env.NEXT_PUBLIC_L2_CHAIN_ID)],
  ],
  ssr: true,
});

const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
};

export function WalletProvider({ children }: Props) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
